var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "full"
    }
  }, [_c('Breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _c('hr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "mt": "20px"
    }
  }), _c('CreateForm', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    ref: "$forms",
    attrs: {
      "value": _vm.glossary,
      "mt": "30px"
    },
    on: {
      "input": function input($event) {
        return _vm.onFormChanged($event);
      }
    }
  }), _c('c-stack', {
    attrs: {
      "mt": "30px",
      "is-inline": "",
      "spacing": "20px"
    }
  }, [_c('c-button', {
    attrs: {
      "type": "button",
      "rounded": "full",
      "w": "289px",
      "h": "62px",
      "as": "router-link",
      "to": {
        name: 'superadmin.glossary'
      }
    }
  }, [_vm._v(" Batal ")]), _c('c-button', {
    attrs: {
      "type": "button",
      "rounded": "full",
      "w": "289px",
      "h": "62px",
      "variant-color": "primary",
      "is-disabled": _vm.isLoading || _vm.isInvalid,
      "disabled": _vm.isLoading || _vm.isInvalid
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.submit();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.isLoading ? 'Menyimpan...' : 'Simpan') + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }